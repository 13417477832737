import React from 'react';
import Layout from "../../components/Layout";
import SEO from "../../components/Seo";
import MainFrontpage from "../../components/MainFrontpage";
import MainLeftColumn from "../../components/MainLeftColumn";
import MainRightColumn from "../../components/MainRightColumn";

// Hreflang data

// Hreflang data
const alternateLangs = [
  {
    hreflang: "en",
    href: "/en"
  },
  {
    hreflang: "da",
    href: "/da"
  },
  {
    hreflang: "de",
    href: "/de"
  },
  {
    hreflang: "se",
    href: "/se"
  },
  {
    hreflang: "no",
    href: "/no"
  },
  {
    hreflang: "nl",
    href: "/nl"
  },
  {
    hreflang: "x-default",
    href: "/en"
  }
];


const Index = ({ location }) => { // Receive location as a prop
  return (
    <Layout location={location} alternateLangs={alternateLangs}>
      <React.Fragment>
        <SEO
          title="Blue River Mountains | Google Analytics & SEO Consulting"
          lang="nl"
          description="Adviesbureau voor SEO & Web Analytics op freelance basis. Leer over SEO of Google Analytics in onze tutorials of neem contact met ons op voor hulp bij meer geavanceerde problemen."
          image="homepage-screenshot"
          alternateLangs={alternateLangs}
          canonical="/nl"
        />
        <MainFrontpage>
          <MainLeftColumn />
          <MainRightColumn />
        </MainFrontpage>
      </React.Fragment>
    </Layout>
  );
};

export default React.memo(Index);

